const BASE_URL = process.env.REACT_APP_SERVICE_URL;
const E_SERVICES_URL = process.env.REACT_APP_E_SERVICE_URL;
const CUSTOMER_SERVICE_URL = process.env.REACT_APP_CUSTOMER_SERVICE_URL;

export const API_URL = {
  PAN_VERIFICATION: `${BASE_URL}/partner/pan-verification`,
  USER_VERIFICATION: `${BASE_URL}/partner/draft/user-verification`,
  DRAFT_STATUS: `${BASE_URL}/partner/draft/status`,
  DASHBOARD_CHART_DATA: `${BASE_URL}/reporting/dashboard/top-charts`,
  REPORTING_FORECLOSURE: `${BASE_URL}/reporting/foreclosure`,
  CALC_BROKERAGE: `${BASE_URL}/brokerage-calculator/getBrokerage/1/100`,
  EXPORT_EXCEL: `${BASE_URL}/brokerage-calculator/getExcel`,
  // partner-login
  PARTNER_LOGIN: `${BASE_URL}/partner/login`,
  PARTNER_LOGOUT: `${BASE_URL}/partner/logout`,
  PARTNER_SET_PASSWORD: `${BASE_URL}/partner/set-password`,
  VALIDATE_PASSWORD_PARTNER: `${BASE_URL}/partner/validate-forget-password-otp`,
  PARTNER_CHANGE_PASSWORD: `${BASE_URL}/profile/changePassword`,
  PARTNER_PROFILE_DETAILS: `${BASE_URL}/profile/details`,
  PARTNER_CHANGE_MOBILE: `${BASE_URL}/profile/update-mobile/request-otp`,
  PARTNER_CHANGE_EMAIL: `${BASE_URL}/profile/update-email/request-otp`,
  PARTNER_CHANGE_MOBILE_VALIDATION: `${BASE_URL}/profile/update-mobile/validate-otp`,
  PARTNER_CHANGE_EMAIL_VALIDATION: `${BASE_URL}/profile/update-email/validate-otp`,
  PARTNER_CHANGE_ADDRESS: `${BASE_URL}/profile/changeAddress`,
  PARTNER_PERMANENT_ADDRESS_CHANGE: `${BASE_URL}/profile/permanent-address`,
  PARTNER_PERMANENT_ADDRESS_CHANGE_FETCH: `${BASE_URL}/profile/digi-locker/fetch`,
  PARTNER_PERMANENT_ADDRESS_CHANGE_CONFIRM: `${BASE_URL}/profile/digi-locker/confirm`,
  PARTNER_PERMANENT_ADDRESS_CHANGE_TRY: `${BASE_URL}/profile/digi-locker/re-try`,
  PARTNER_PROCESS_REDIRECT: `${BASE_URL}/profile/digi-locker/process-redirect`,
  PARTNER_PERMANENT_MANUAL_ADDRESS: `${BASE_URL}/profile/manual/update-permanent-address`,
  // individual
  PERSONAL_DETAILS: `${BASE_URL}/partner/draft/personal-details`,
  GET_DIGILOCKER_TRAN: `${BASE_URL}/partner/draft/digilocker/generate-tran-id`,
  DIGI_LOCKER_FORM_URL: `${E_SERVICES_URL}/digi-locker/open`,
  DIGI_LOCKER_FORM_URL_CUSTOMER: `${E_SERVICES_URL}/digi-locker/customer/kyc/open`,
  DIGI_LOCKER_FORM_URL_PROFILE: `${E_SERVICES_URL}/digi-locker/customer/profile/permanent-address-kyc/open`,
  ADDRESS_DETAILS: `${BASE_URL}/partner/draft/address-details`,
  BANK_DETAILS: `${BASE_URL}/partner/draft/bank-details`,
  BANK_IFSC_DETAILS: `${BASE_URL}/common/bank-details`,
  BUSINESS_EXPERIENCE_DETAILS: `${BASE_URL}/partner/draft/business-experience`,
  NOMINEE_DETAILS: `${BASE_URL}/partner/draft/nominee-details`,
  OTHER_DETAILS: `${BASE_URL}/partner/draft/other-details`,
  GST_DETAILS: `${BASE_URL}/partner/draft/gst-details`,
  ESIGN_GET: `${BASE_URL}/partner/draft/e-sign`,
  ESIGN_GENERATE: `${BASE_URL}/partner/generate-esign-reference-number`,
  INITIATE_ESIGN: `${BASE_URL}/partner/initiate-esign`,
  ESIGN_STATUS: `${BASE_URL}/partner/draft/e-sign/status`,
  ESIGN_SPECIMEN: `${BASE_URL}/partner/draft/e-sign/specimen-upload`,
  // non-individual
  ENTITY_DETAILS: `${BASE_URL}/partner/draft/entity-details`,
  BUSINESS_DETAILS: `${BASE_URL}/partner/draft/business-details`,
  LIST_OF_PARTNERS: `${BASE_URL}/partner/draft/partner-list`,
  DOCUMENTS: `${BASE_URL}/partner/draft/documents`,
  // customer
  CUSTOMER_TOP_UP: `${CUSTOMER_SERVICE_URL}/top-up/initiate`,
  CUSTOMER_VALIDATE_NUMBER: `${CUSTOMER_SERVICE_URL}/customer/validate-customer-number`,
  REQUEST_OTP: `${CUSTOMER_SERVICE_URL}/customer/login/request-otp`,
  GENERATE_OTP: `${CUSTOMER_SERVICE_URL}/customer/login/validate-otp`,
  RESEND_OTP: `${CUSTOMER_SERVICE_URL}/customer/login/resend-otp`,
  CUSTOMER_LINK_LOGIN: `${CUSTOMER_SERVICE_URL}/customer/login/fresh-login`,
  SELECT_LAS: `${CUSTOMER_SERVICE_URL}/customer/dashboard`,
  FETCH_PROCESS: `${CUSTOMER_SERVICE_URL}/customer/process-file`,
  FETCH_IMPORT: `${CUSTOMER_SERVICE_URL}/customer/import`,
  FETCH_CAS: `${CUSTOMER_SERVICE_URL}/customer/import`,
  GET_PRODUCT_FEATURES: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/product-features`,
  SAVE_CONCENT_PRODUCT_FEATURES: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/product-features`,
  KYC_ADDRESS: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/address-details`,
  CUSTOMER_DIGILOCKER_INFO: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/digi-locker/fetch`,
  CUSTOMER_DIGILOCKER_RETRY: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/digi-locker/re-try`,
  CUSTOMER_DIGILOCKER_POST: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/digi-locker/confirm`,
  CUSTOMER_LAST_STAGE: `${CUSTOMER_SERVICE_URL}/summary/loan-application-status`,
  PHOTO_VERIFICATION: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/photo-verification/initiate`,
  PHOTO_VERIFICATION_FETCH: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/photo-verification/fetch`,
  PHOTO_VERIFICATION_APPROVE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/photo-verification/approve`,
  EXISTING_KYC_PROCEED: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/personal-details/use-existing-kyc`,
  EMI_SAVE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/emi-config/emi-options/save`,
  SECURITIES_SELECTION: `${CUSTOMER_SERVICE_URL}/customer/securities-selection/fetch-all`,
  FETCH_ALL_SECURITIES: `${CUSTOMER_SERVICE_URL}/customer/securities-selection/fetch-eligibility`,
  SAVE_SECURITIES: `${CUSTOMER_SERVICE_URL}/customer/securities-selection/save`,
  FETCH_EMI: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/emi-config/emi-options`,
  FETCH_SUMMARY: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/summary`,
  SANCTION_CONTROLLER: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/sanction`,
  VALIDATE_KFIN_OTP: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/pledge/kfin/validate-otp`,
  AGREEMENT_INITIATE_OTP: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/pledge/agreement/initate`,
  AGREEMENT_VERIFY_OTP: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/pledge/agreement/verify-otp`,
  VIEW_DOCUMENT: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/pledge/view-doc`,
  INITIATE_KFIN_REQ: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/pledge/kfin/request-otp`,
  INITIATE_CAMS: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/pledge/cams/initate`,
  CAMS_CHECK_STATUS: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/pledge/cams/status-check`,
  LOAD_DOCUMENTS: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/pledge/view-all-doc-list`,
  MANDATED_TYPE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/mandate/allowed-types`,
  MANDATED_DOWNLOAD_IMAGE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/mandate/physical-mandate/download-mandate-image`,
  PHYSICAL_MANDATE_SUBMIT: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/mandate/physical-mandate/apply-mandate`,
  GET_EMANDATE_DATA: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/mandate/e-mandate`,
  INITIATE_E_PAY_REQ: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/mandate/e-mandate/epay`,
  KYC_BANK_LIST: `${CUSTOMER_SERVICE_URL}/customer/list-banks`,
  KYC_BANK_DATA: `${CUSTOMER_SERVICE_URL}/customer/bank`,
  REQUEST_CAS_CUSTOMER: `${CUSTOMER_SERVICE_URL}/customer/request-cas`,
  DOCUMENT_LIST: `${CUSTOMER_SERVICE_URL}/customer/bank/document-list`,
  GET_CUSTOMER_CUTS: `${BASE_URL}/custdocs/getCustomers`,
  GET_LOAN_ACCOUNT_CUTS: `${BASE_URL}/custdocs/loanAccounts`,
  GET_DOCUMENTS_CUTS: `${BASE_URL}/custdocs/getDocument`,
  DOWNLOAD_IVR_FILE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/downloadIVRFile`,
  DOWNLOAD_URF_FILE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/downloadUrfFile`,
  GENERATE_IVR_FILE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/generateIvrFile`,
  GET_CUSTOMER_NAME: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/getCustomerName`,
  GET_NON_DEMAT_CAMS_CHARGES: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/getNonDematCamsCharges`,
  GET_REPORT_DATA: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/getReportData`,
  GET_RQST_UNITS: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/getRequestUnitsDtls`,
  GET_RTA_UNPLEDGE_CHARGE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/getRtaUnpledgeCharge`,
  GET_TYPES_SECURITY: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/getTypeOfSecurity`,
  GET_UNPLEDGE_REQ: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/getUnpledgeReqDtlsOnView`,
  INSERT_ADD_REPORT: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/insertAddReportData`,
  INSERT_PAYMENT_DATA_BEFORE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/insertPaymentDataBeforeFinPay`,
  SUCCESS_MAIL: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/successMail`,
  UPDATE_CANCEL_REQ: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/updateCancelRequest`,
  UPDATE_LOAN_CHARGES_PAYMST: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/unpledge/updateLoanChargesPayMst`,
  PERMANENT_ADDRESS_CHANGE: `${CUSTOMER_SERVICE_URL}/profile/permanent-address`,
  PERMANENT_ADDRESS_CHANGE_FETCH: `${CUSTOMER_SERVICE_URL}/profile/digi-locker/fetch`,
  PERMANENT_ADDRESS_CHANGE_CONFIRM: `${CUSTOMER_SERVICE_URL}/profile/digi-locker/confirm`,
  PERMANENT_ADDRESS_CHANGE_TRY: `${CUSTOMER_SERVICE_URL}/profile/digi-locker/re-try`,
  CUSTOMER_PROFILE: `${CUSTOMER_SERVICE_URL}/profile`,
  REQ_EMAIL_OTP: `${CUSTOMER_SERVICE_URL}/profile/update-email/request-otp`,
  VALIDATION_EMAIL_OTP: `${CUSTOMER_SERVICE_URL}/profile/update-email/validate-otp`,
  REQ_MOBILE_OTP: `${CUSTOMER_SERVICE_URL}/profile/update-mobile/request-otp`,
  VALIDATION_MOBILE_OTP: `${CUSTOMER_SERVICE_URL}/profile/update-mobile/validate-otp`,
  UPDATE_CORRESPONDENCE_ADDRESS: `${CUSTOMER_SERVICE_URL}/profile/update-correspondence-address`,
  UPDATE_PERMANENT_ADDRESS: `${CUSTOMER_SERVICE_URL}/profile/manual/update-permanent-address`,
  HELP_DESK_QUERY: `${CUSTOMER_SERVICE_URL}/helpdesk/getQueryTypes`,
  HELP_DESK_QUERY_SUBMIT: `${CUSTOMER_SERVICE_URL}/helpdesk/submitQuery`,
  CUSTOMER_EMANDATE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/mandate/existing-mandate`,
  EXISTING_MANDATE_SAVE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/mandate/existing-mandate/reuse`,
  CUSTOMER_MANDATE_SAVE: `${CUSTOMER_SERVICE_URL}/customer/bank/mandate-change/save`,
  CUSTOMER_MAP_MANDATE: `${CUSTOMER_SERVICE_URL}/customer/bank/mandate-change/map-bank`,
  BANK_MANDATE_APPROVAL: `${CUSTOMER_SERVICE_URL}/customer/bank/mandate-change/bank-under-approval`,
  CUSTOMER_REQ_OTP: `${CUSTOMER_SERVICE_URL}/customer/mfcentral/fetch-securities/request-otp`,
  CUSTOMER_VERIFY_OTP: `${CUSTOMER_SERVICE_URL}/customer/mfcentral/fetch-securities/verify-otp`,
  CUSTOMER_LOGOUT: `${CUSTOMER_SERVICE_URL}/customer/logout`,
  //business-reports
  GET_BUSINESS_REPORTS: `${BASE_URL}/business-reports/`,
  GET_BROKERAGE_CALCULATOR: `${BASE_URL}/brokerage-calculator/config`,
  EMI_CALCULATOR: `${BASE_URL}/emi-calculator/`,
  //reporting
  GET_LOAN_ACCOUNTS: `${CUSTOMER_SERVICE_URL}/customer/loanAccounts`,
  GET_DOCUMENT: `${CUSTOMER_SERVICE_URL}/customer/getDocument`,
  GET_LOAN_STATUS_PARTNER: `${BASE_URL}/reporting/getLoanStatus`,
  GET_LOAN_STATUS_PARTNER_LOGS: `${BASE_URL}/reporting/getLoanStatusLogs`,
  GET_MANDATE_PARTNER_DETAILS: `${BASE_URL}/reporting/getPledgeData`,
  GET_LOAN_STATUS_FILTER_DATA: `${BASE_URL}/reporting/getLoanStatus/filter-data`,
  GET_LOAN_ACCOUNT_NO: `${CUSTOMER_SERVICE_URL}/payments/getLanNo`,
  GET_LOAN_STATUS_PAYMENT: `${CUSTOMER_SERVICE_URL}/payments/getLoanStatus`,
  GET_MANDATE_STATUS: `${CUSTOMER_SERVICE_URL}/payments/getMandateStatus`,
  GET_PAYMENT_BANK: `${CUSTOMER_SERVICE_URL}/payments/getBankDetails`,
  CHECK_PENDING_TRN: `${CUSTOMER_SERVICE_URL}/payments/checkPendingTrn`,
  NET_BANK_FUND: `${CUSTOMER_SERVICE_URL}/payments/callNetBankFundCollectionApi`,
  CHECK_VPA: `${CUSTOMER_SERVICE_URL}/payments/checkVPA`,
  CALL_UPI_FUND: `${CUSTOMER_SERVICE_URL}/payments/callUPIFundCollectionRequestApi`,
  PAYMENT_STATUS_REDIRECT: `${CUSTOMER_SERVICE_URL}/payments/payment-status`,
  CALL_WEB_HOOK: `${CUSTOMER_SERVICE_URL}/payments/callUPIWebhookApi`,
  GET_LOAN_ACCOUNT_REQ_DATA: `${CUSTOMER_SERVICE_URL}/payments/getPaymentRequestData`,
  GET_LOAN_STATUS: `${CUSTOMER_SERVICE_URL}/shortfall/getLoanStatus`,
  GET_LOAN_STATUS_LOGS: `${CUSTOMER_SERVICE_URL}/shortfall/getLoanStatusLogs`,
  GET_MANDATE_DATA: `${CUSTOMER_SERVICE_URL}/shortfall/getMandateData`,
  GET_MANDATE_DETAILS: `${CUSTOMER_SERVICE_URL}/shortfall/getPledgeData`,
  GET_TENURE_LOANID: `${CUSTOMER_SERVICE_URL}/payments/getTenureAndLoanId`,
  POST_PAYMENT_PAY: `${CUSTOMER_SERVICE_URL}/payments/insertPaymentDataBeforeFinPay`,
  FETCH_ADDITIONAL_PLEDGE: `${CUSTOMER_SERVICE_URL}/customer/securities-selection/additional-pledge/fetch-all`,
  SAVE_SECURITY_ADDITIONAL: `${CUSTOMER_SERVICE_URL}/customer/securities-selection/additional-pledge/save`,
  PORTFOLIO_PROCESS: `${BASE_URL}/customer/draft/process-file`,
  PORTFOLIO_GET: `${BASE_URL}/customer/draft/info`,
  CUSTOMER_SIGNUP: `${BASE_URL}/customer/draft/sign-up`,
  CUSTOMER_PORTFOLIO: `${BASE_URL}/customer/draft/import`,
  CUSTOMER_ELIGIBILITY: `${BASE_URL}/customer/draft/eligibility`,
  EXISTING_PAN_VERIFICATION: `${BASE_URL}/customer/draft/customers`,
  REQUEST_CAS: `${BASE_URL}/customer/draft/request-cas`,
  SAMPLE_EXCEL_FILE: `${BASE_URL}/customer/draft/sample-file`,
  PURPOSE_LOAN_GET: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/personal-details/purpose-list`,
  KYC_PERSONAL_POST: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/personal-details`,
  KYC_PERSONAL_GET: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/e-kyc/personal-details`,
  EXISTING_CUSTOMER: `${BASE_URL}/customer/draft/customers`,
  TOP_UP_APPLICATION: `${BASE_URL}/customer/new-application/top-up`,
  VALIDATE_PARTNER_CODE: `${BASE_URL}/partner/validate-partner-code`,
  GENERATE_PARTNER_CODE: `${BASE_URL}/partner/forgot-password-request`,
  GET_QUERY_TYPE: `${BASE_URL}/helpdesk/getQueryTypes`,
  SUBMIT_QUERY: `${BASE_URL}/helpdesk/submitQuery`,
  FETCH_CUSTOMER_INFO: `${BASE_URL}/customer/draft/mf-central/request-cas`,
  REQUEST_CAS_STATUS: `${BASE_URL}/customer/draft/mf-central/request-cas/status`,
  DISABLE_LINK: `${BASE_URL}/customer/draft/mf-central/request-cas/disable-link`,
  common: {
    FILEUPLOAD: `${BASE_URL}/common/fileUpload`,
    FILEUPLOAD_CUSTOMER: `${CUSTOMER_SERVICE_URL}/common/fileUpload`,
    country: `${BASE_URL}/common/geo/country`,
    state: `${BASE_URL}/common/geo/state`,
    city: `${BASE_URL}/common/geo/city`,
    FETCH_FILE: `${BASE_URL}/common/fetchFile`,
    FETCH_FILE_CUSTOMER: `${CUSTOMER_SERVICE_URL}/common/fetchFile`,
  },
  DRAFTLOGIN: `${BASE_URL}/partner/draft/login`,
  VERIFYOTP: `${BASE_URL}/partner/draft/login/verify-otp`,
  DIGILOCKER_SAVE: `${BASE_URL}/partner/draft/personal-details/confirm/digi-locker`,
  DIGI_FETCH: `${BASE_URL}/digi-locker/fetch`,
  DRAFTLOGOUT: `${BASE_URL}/partner/draft/logout`,
  // Captcha call
  GETCAPTCHA: `${BASE_URL}/captcha/getCaptcha`,
  // Annual Report
  ARGETOTP: `${BASE_URL}/corporategovernance/getOTP`,
  ARVALIDATEOTP: `${BASE_URL}/corporategovernance/validateOTP`,
  ARFILENAME: `${BASE_URL}/corporategovernance/getARFileList`,
  ARDOWNLOAD: `${BASE_URL}/corporategovernance/download`,
  // CHECK PAN STATUS
  GET_PAN_STATUS: `${CUSTOMER_SERVICE_URL}/common/pan-verification-status`,
  // VCIP : REQUEST 73303 (DONE BY GAURAV SHARMA --> gaurav38)
  CHECK_VCIP_SKIPBUTTON: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/checkVCIPSkip`,
  CHECK_STATE_ENABLE_FOR_VCIP: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/checkStateEnableForVCIP`,
  GET_VCIP_OPTION_TABLE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/getVcipOptionTable`,
  SET_VCIP_CONSENT: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/setConsent`,
  VCIP_DIGILOCKER: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/digilocker/open`,
  CUSTOMER_VCIP_DIGILOCKER: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/digi-locker/fetch`,
  UPDATE_VCIP_DIGILOCKER_KYC: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/update-kycDetails`,
  VCIP_VALIDATE_OFFICE_TIME: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/validate-officeTime`,
  VCIP_CHECK_PROFILE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/get-vcip-profile`,
  VCIP_CONTINUE_WITH_MOBILE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/continue-with-mobile`,
  VCIP_INPROGRESS: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/inProgress`,
  VCIP_MANDATE_PENDING: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/check-mandate-pending`,
  VCIP_GET_CONSENT: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/get-consent`,
  VCIP_SET_VCIP_DONE: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/set-vcip-done`,
  VCIP_GET_DRAFT_ID: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/get-draftId`,
  VCIP_GET_COMPLETED_DETAILS: `${CUSTOMER_SERVICE_URL}/customer/loan-status-report/vcip/get-vcip-completed-details`,
  VCIP_ISGIVEVCIPTAB: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/vcip/isGiveVCIPTab`,

  // PAYMENT : REQ 70556 
  IS_ELIGIBLE_FOR_PREPAY: `${CUSTOMER_SERVICE_URL}/payments/check-is-eligible-for-prepayment`,
  GET_MANDATE_STATUS_LOGS: `${CUSTOMER_SERVICE_URL}/shortfall/getMandateStatusLogs`,
  GET_MANDATE_STATUS_PARTNER_LOGS: `${BASE_URL}/reporting/getMandateStatusLogs`,
  GET_REPORT_FILTER_DATA: `${BASE_URL}/reporting/getReportFilterData`,

  // MANDATE CHARGE : REQ 76265
  UPDATE_MANDATE_CHARGES: `${CUSTOMER_SERVICE_URL}/customer/loan-journey/mandate/update-charges`,

};